import { useEffect, useState } from 'react';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import axios from 'axios';
import { useAdminContext } from '../../global/AdminContext';
import { useNavigate, useParams, useLocation } from 'react-router';
import NewTable from './NewTable';

export function AdminDashboard() {
	const { admin } = useAdminContext();
	const navigate = useNavigate();
	const location = useLocation();
	const { type } = useParams(); //types tell whether college or not
	const [jobPostings, setJobPostings] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (admin.loading) return;
		if (!admin.exists) navigate('/');
		setLoading(false);
	}, [admin, navigate]);

	useEffect(() => {
		// make api call
		async function fetchAllJobPostings() {
			setLoading(true);
			try {
				if (admin.loading) return;
				if (!admin.exists) {
					setLoading(false);
					console.log('admin data not found. please login and try again');
					return;
				}
				const response = await axios.get(`${API_URL}/labs/jobpostings`, {
					headers: {
						Authorization: `Bearer ${admin.token}`,
					},
					params: {
						role: type,
					},
				});
				const result = response.data.map((jobPosting) => {
					return {
						id: jobPosting.job?._id,
						companyId: jobPosting.job.company_id,
						jobRole: jobPosting.job.job_role,
						allCompletedInterviews: jobPosting.allCompletedInterviews,
						allIncompletedInterviews: jobPosting.allIncompletedInterviews,
						totalApplicants: jobPosting.totalApplicants,
						questions_link: jobPosting?.job?.questions_link,
					};
				});
				setJobPostings(result);
				console.log(result);
				console.log('--- all jobpostings fetched successfully ---');
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		}

		fetchAllJobPostings();
	}, [admin, location, type]);

	return (
		<>
			{loading ? (
				<CircularStatic />
			) : (
				<NewTable jobPostings={jobPostings} setJobPostings={setJobPostings} />
			)}
		</>
	);
}
