import React, { useRef, useState, useEffect } from 'react';

const VideoPlayer = ({ questionVideo, diff }) => {
	const videoRef = useRef(null);
	const seekBarRef = useRef(null);

	const [seekValue, setSeekValue] = useState(0);
	console.log(diff, 'diff');
	useEffect(() => {
		const video = videoRef.current;
		if (diff) {
			video.currentTime = diff;
		}

		const seekBar = seekBarRef.current;

		const handleTimeUpdate = () => {
			const value = (100 / video.duration) * video.currentTime;
			setSeekValue(value);
		};

		video.addEventListener('timeupdate', handleTimeUpdate);

		return () => {
			video.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, []);

	return (
		<div>
			<video id="video" ref={videoRef} width="600" height="400" controls>
				<source src={questionVideo} type="video/mp4" />
			</video>
			<br></br>
			<br></br>
			<br></br>
		</div>
	);
};

export default VideoPlayer;
