import { useState, useEffect } from 'react';
import { Stack, Typography, Box, Container } from '@mui/material';
import { EndInterview } from '../EndInterview';
import { Header } from '../shared/Header';
import { InterviewTrivia } from '../InterviewTrivia';
import { Uploader } from '../../helpers/Uploader';
import { useParams } from 'react-router';
import SwiperRatingContainer from './swipercontainer';

export const EndScreen = () => {
	const params = useParams();

	const fullName = params?.fullName;

	return (
		<>
			<Header fullName={fullName} />
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
					mt: '70px',
				}}
				maxWidth="md"
			>
				<Box>
					<Typography
						variant="h3"
						gutterBottom
						sx={{
							color: '#002244',
							fontWeight: 'bold',
						}}
					>
						Thank you for completing the interview!
					</Typography>
					<Typography variant="body1" gutterBottom sx={{ color: '#002244' }}>
						Your responses have been recorded. Our team will review your answers and get
						back to you soon.
					</Typography>
				</Box>
				<Box
					sx={{
						width: '80%',
						height: '225px',
						marginTop: 3,
					}}
				>
					<SwiperRatingContainer />
				</Box>
			</Container>
		</>
	);
};
