import React, { useRef, useEffect } from 'react';

const VideoThumbnail = ({ videoUrl, width = 150, height = 100 }) => {
	const canvasRef = useRef(null);
	const videoRef = useRef(null);

	useEffect(() => {
		const video = videoRef.current;
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		// Set the canvas size to the specified width and height
		canvas.width = width;
		canvas.height = height;

		const handleLoadedData = () => {
			video.currentTime = 10;
		};

		const handleSeeked = () => {
			context.drawImage(video, 0, 0, width, height);
			// Remove event listeners to prevent drawing the image multiple times
			video.removeEventListener('loadeddata', handleLoadedData);
			video.removeEventListener('seeked', handleSeeked);
		};

		// Add event listeners
		video.addEventListener('loadeddata', handleLoadedData);
		video.addEventListener('seeked', handleSeeked);

		// Load the video
		video.src = videoUrl;
		video.load();

		// Cleanup event listeners on unmount
		return () => {
			video.removeEventListener('loadeddata', handleLoadedData);
			video.removeEventListener('seeked', handleSeeked);
		};
	}, [videoUrl, width, height]);

	return (
		<div>
			<video ref={videoRef} style={{ display: 'none' }} />
			<canvas style={{ borderRadius: '5px', overflow: 'hidden' }} ref={canvasRef}></canvas>
		</div>
	);
};

export default VideoThumbnail;
