import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './candidateReportV2.css';
import { Stack } from '@mui/material';

const theme = createTheme({
	palette: {
		success: {
			main: '#66bb6a',
		},
		error: {
			main: '#FF0000',
		},
		warning: {
			main: '#ffc107',
		},
		neutral: {
			main: '#cccccc',
		},
	},
});

const nameToLabelMapping = {
	question_relevance: 'Directly addressing the question',
	specificity: 'Providing specific examples',
	tailoring: 'Tailoring the response to the job',
	topic_relevance: 'Staying on topic',
	concern_relevance: 'Addressing the underlying concerns',
};

const CandidateReportOld = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [reportData, setReportData] = useState();
	const [overallScorePercentage, setOverallScorePercentage] = useState();
	const [englishProficiencyScorePercentage, setEnglishProficiencyScorePercentage] = useState();
	const [individualAverageScore, setIndividualAverageScore] = useState();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [role, setRole] = useState('');
	const params = useParams();

	const { key } = params;

	useEffect(() => {
		const getReportData = async () => {
			const { data } = await axios.get(`${API_URL}/labs/generate-report/${key}`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			});
			console.log(data);
			setReportData(data);
			setFirstName(data.first_name);
			setLastName(data.last_name);
			setRole(data.job_role);
			console.log(data);
			setOverallScorePercentage(parseInt(data?.overall_score));
			setEnglishProficiencyScorePercentage(parseInt(data?.english_proficiency_score));
			setIndividualAverageScore(data?.individual_params_scores);
		};

		if (key) {
			getReportData();
		}
	}, [key]);

	useEffect(() => {
		if (reportData) {
			setIsLoading(false);
		}
	}, [reportData]);

	const getPathColor = (value) => {
		return value >= 75 ? '#66bb6a' : value >= 50 && value < 75 ? '#ffc107' : '#FF0000';
	};

	const getLinearProgressColor = (value) => {
		return value >= 75 ? 'success' : value >= 50 && value < 75 ? 'warning' : 'error';
	};

	return (
		<div style={{ backgroundColor: '#ECF7FA' }}>
			<div className="report-container">
				{isLoading && <CircularStatic />}
				{!isLoading && (
					<>
						<div className="report-details">
							<div className="report-details-name">
								<div className="report-details-name-value">
									{`${firstName} ${lastName}`}
								</div>
								<div className="report-details-subDetails-value">
									Interviewed for : {role}
								</div>
							</div>
							{/* <div className="report-details-date">
              <div className="report-details-date-value">
                29 April 2023
              </div>
            </div> */}
						</div>
						<div className="report-details-container">
							<Stack
								direction={{ sm: 'row', xs: 'column' }}
								s={{ width: '100%' }}
								spacing={3}
								justifyContent="center"
								alignItems="center"
							>
								{/* <div className="performance-summary">
                <h4>Our Recommendation</h4>
              </div> */}
								<Stack justifyContent="center" alignItems="center">
									<ThemeProvider theme={theme}>
										<LinearProgress
											variant="determinate"
											color={
												overallScorePercentage < 50 ? 'error' : 'neutral'
											}
											sx={{
												width: '200px',
												height: '20px',
												borderRadius: '28px',
												backgroundColor: '#cccccc',
											}}
										/>
									</ThemeProvider>
									<h4>Not Recommended</h4>
								</Stack>
								<Stack justifyContent="center" alignItems="center">
									<ThemeProvider theme={theme}>
										<LinearProgress
											variant="determinate"
											color={
												overallScorePercentage >= 50 &&
												overallScorePercentage < 75
													? 'warning'
													: 'neutral'
											}
											value={overallScorePercentage}
											sx={{
												width: '200px',
												height: '20px',
												borderRadius: '28px',
												backgroundColor: '#cccccc',
											}}
										/>
									</ThemeProvider>
									<h4>Moderately Recommended</h4>
								</Stack>
								<Stack justifyContent="center" alignItems="center">
									<ThemeProvider theme={theme}>
										<LinearProgress
											variant="determinate"
											color={
												overallScorePercentage >= 75 ? 'success' : 'neutral'
											}
											value={overallScorePercentage}
											sx={{
												width: '200px',
												height: '20px',
												borderRadius: '28px',
												backgroundColor: '#cccccc',
											}}
										/>
									</ThemeProvider>
									<h4>Highly Recommended</h4>
								</Stack>
							</Stack>
							<div className="performance-summary-container">
								<div className="performance-summary">
									<h4>Performance Summary</h4>
								</div>
								<div className="performance-stats-container">
									<div className="scores-container">
										<Stack
											direction={{ sm: 'row', xs: 'column' }}
											sx={{ width: '100%' }}
											spacing={3}
											justifyContent="center"
											alignItems="center"
										>
											<Stack justifyContent="center" alignItems="center">
												<h3>Overall Score</h3>
												<div
													style={{ width: '150px', height: '150px' }}
													justifyContent="center"
													alignItems="center"
												>
													<CircularProgressbar
														strokeWidth={12}
														value={overallScorePercentage}
														text={`${overallScorePercentage}%`}
														styles={buildStyles({
															textSize: '16px',
															textColor:
																getPathColor(
																	overallScorePercentage
																),
															pathColor:
																getPathColor(
																	overallScorePercentage
																),
														})}
													/>
												</div>
											</Stack>
											<Stack justifyContent="center" alignItems="center">
												<h3>English Proficiency</h3>
												<div
													style={{ width: '150px', height: '150px' }}
													justifyContent="center"
													alignItems="center"
												>
													<CircularProgressbar
														strokeWidth={12}
														value={englishProficiencyScorePercentage}
														text={`${englishProficiencyScorePercentage}%`}
														styles={buildStyles({
															textSize: '16px',
															textColor: getPathColor(
																englishProficiencyScorePercentage
															),
															pathColor: getPathColor(
																englishProficiencyScorePercentage
															),
														})}
													/>
												</div>
											</Stack>
											<div className="performance-legend">
												<div className="legendItem">
													<div className="success" />
													<h4>Advanced</h4>
												</div>
												<div className="legendItem">
													<div className="warning" />
													<h4>Intermediate</h4>
												</div>
												<div className="legendItem">
													<div className="error" />
													<h4>Beginner</h4>
												</div>
											</div>
										</Stack>
									</div>
								</div>
							</div>
							<div className="evaluation-score-container">
								<div className="performance-summary">
									<h4>Evaluation Parameters & Score</h4>
								</div>
								<Stack>
									{(individualAverageScore || []).map((values) => {
										const key = Object.keys(values);
										const value = Object.values(values);
										const scaledScore = (value[0] / 100) * 5;
										const pathColor = getLinearProgressColor(value);
										return (
											<Stack
												direction={{ sm: 'row', xs: 'column' }}
												justifyContent="center"
												alignItems="center"
												spacing={2}
											>
												<Stack
													sx={{ width: '300px' }}
													textAlign={{ sm: 'start', xs: 'center' }}
												>
													<h4>{nameToLabelMapping[key]}</h4>
												</Stack>
												<ThemeProvider theme={theme}>
													<LinearProgress
														variant="determinate"
														color={pathColor}
														value={value}
														sx={{
															width: '200px',
															height: '20px',
															borderRadius: '28px',
															backgroundColor: '#cccccc',
														}}
													/>
												</ThemeProvider>
												<Rating
													name="half-rating-read"
													defaultValue={parseInt(scaledScore)}
													max={5}
													precision={0.1}
													readOnly
												/>
											</Stack>
										);
									})}
								</Stack>
								<div className="question-details">
									<div className="performance-summary">
										<h4>Interview Report</h4>
									</div>
									<div className="question-details-container">
										{reportData?.interview_details?.map((data) => {
											const {
												question_id,
												question_text,
												candidate_answer,
												question_relevance,
												specificity,
												tailoring,
												topic_relevance,
												concern_relevance,
											} = data;
											return (
												<>
													{question_id !==
														reportData?.interview_details.length -
															1 && (
														<>
															<div
																key={question_id}
																className="question-details"
															>
																<h4 className="heading">
																	Question {question_id}:
																</h4>
																<h5>{question_text}</h5>
																<h4 className="heading">
																	Candidate Response:
																</h4>
																<h5>{candidate_answer}</h5>
																<h4 className="heading">
																	Score & Feedback:
																</h4>
																<div>
																	<h4 className="subHeading">
																		1.{' '}
																		{
																			nameToLabelMapping[
																				'question_relevance'
																			]
																		}
																	</h4>
																	<span className="rating">
																		Rating:{' '}
																		{
																			question_relevance?.question_relevance
																		}
																		,
																	</span>
																	<span className="rating">
																		{question_relevance?.reason}
																	</span>
																</div>
																<div>
																	<h4 className="subHeading">
																		2.{' '}
																		{
																			nameToLabelMapping[
																				'specificity'
																			]
																		}
																	</h4>
																	<span className="rating">
																		Rating:{' '}
																		{specificity?.specificity},
																	</span>
																	<span className="rating">
																		{specificity?.reason}
																	</span>
																</div>
																<div>
																	<h4 className="subHeading">
																		3.{' '}
																		{
																			nameToLabelMapping[
																				'tailoring'
																			]
																		}
																	</h4>
																	<span className="rating">
																		Rating:{' '}
																		{tailoring?.tailoring},
																	</span>
																	<span className="rating">
																		{tailoring?.reason}
																	</span>
																</div>
																<div>
																	<h4 className="subHeading">
																		4.{' '}
																		{
																			nameToLabelMapping[
																				'topic_relevance'
																			]
																		}
																	</h4>
																	<span className="rating">
																		Rating:{' '}
																		{
																			topic_relevance?.topic_relevance
																		}
																		,
																	</span>
																	<span className="rating">
																		{topic_relevance?.reason}
																	</span>
																</div>
																<div>
																	<h4 className="subHeading">
																		5.{' '}
																		{
																			nameToLabelMapping[
																				'concern_relevance'
																			]
																		}
																	</h4>
																	<span className="rating">
																		Rating:{' '}
																		{
																			concern_relevance?.concern_relevance
																		}
																		,
																	</span>
																	<span className="rating">
																		{concern_relevance?.reason}
																	</span>
																</div>
															</div>
															{question_id !==
																reportData?.interview_details
																	.length -
																	2 && <hr />}
														</>
													)}
												</>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default CandidateReportOld;
