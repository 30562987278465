import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Stack } from '@mui/system';
import { Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router';
import ContactPageIcon from '@mui/icons-material/ContactPage';
const Interview = (props) => {
	const [loading, setLoading] = useState(false);
	const [interviewKey, setInterviewKey] = useState('');
	const [fullName, setFullName] = useState(props.fullName);

	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	console.log(sessionStorage);
	const [studentaccess, setstudentaccess] = useState(false);
	useState(() => {
		if (sessionStorage.getItem('student_interview_access') === 'true') {
			setstudentaccess(true);
		}
	}, [sessionStorage]);

	const createInterview = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(
				`${API_URL}/labs/student-interview/create/${props.interview_id}`,
				{},
				{
					headers: {
						authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
					},
				}
			);
			console.log(data);
			if (data.success) {
				toast.success('Interview Created Successfully');
				setInterviewKey(data.data._id);

				navigate(`/check/${fullName}/${data.data._id}`, {
					state: {
						fullName: fullName,
						interviewKey: data.data._id,
						isStudentInterview: true,
					},
				});
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};
	return (
		<Stack
			bgcolor={'#fff'}
			width={{ sm: '380px', xs: '100%' }}
			height={{ xs: 'auto' }}
			borderRadius={'10px 25px 10px 25px'}
			//border={"2px solid #cccccc"}
			// style={{
			//   overflow: "hidden",
			// }}
			boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1)'}
		>
			<Dialog open={loading}>
				<DialogTitle>Creating Interview</DialogTitle>
				<DialogContent>
					<Stack
						direction={'column'}
						spacing={2}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<CircularProgress />
						<DialogContentText>Please Wait...</DialogContentText>
					</Stack>
				</DialogContent>
				{/* <Dialog open={open}>
                    <DialogTitle>
                        {"Do you want to start interview now?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Let Google help apps determine location. This means
                            sending anonymous location data to Google, even when
                            no apps are running.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>No</Button>
                        <Button onClick={() => setOpen(false)}>Yes</Button>
                    </DialogActions>
                </Dialog> */}
			</Dialog>
			<Toaster />
			<Box
				bgcolor="#1565C0"
				height="80px"
				borderRadius={'10px 25px 10px 25px'}
				padding="10px 8px"
				display="flex"
				alignItems="center"
			>
				<ContactPageIcon
					style={{ color: 'white', fontSize: 35 }}
					sx={{ marginRight: 1, marginLeft: 2 }}
				/>
				<Typography style={{ color: 'white' }} fontWeight={900} fontSize={'1.2rem'}>
					{props.role}
				</Typography>
			</Box>
			<Box p={4}>
				<Stack direction={'column'} spacing={2}>
					<Typography fontSize={'1rem'} color={'#cccccc'}>
						{props.industry}
					</Typography>
					<Typography
						style={{
							overflow: 'hidden',
							display: '-webkit-box',
							WebkitLineClamp: 4,
							WebkitBoxOrient: 'vertical',
							maxWidth: '100%', // or specify a fixed width
						}}
						fontSize={{ sm: '1rem', xs: '1rem' }}
					>
						{props?.interviewDescription}
					</Typography>
					<Stack
						direction={'row'}
						spacing={2}
						justifyContent={'space-between'}
						marginTop={'20px'}
					>
						<Stack
							direction={'row'}
							spacing={1}
							justifyContent={'center'}
							alignItems={'center'}
						>
							{/* <SignalCellularAltIcon
                style={{
                  color: "#1565C0",
                }}
              /> */}
							<AccessTimeOutlinedIcon style={{ color: '#1565C0' }} />
							<Typography
								fontWeight={'bold'}
								fontSize={{ sm: '1.06rem', xs: '1rem' }}
							>
								{props.interview_level}
							</Typography>
						</Stack>
						<Stack
							direction={'row'}
							spacing={1}
							justifyContent={'center'}
							alignItems={'center'}
						>
							<QuizOutlinedIcon
								style={{
									color: '#1565C0',
								}}
							/>
							<Typography
								fontWeight={'bold'}
								fontSize={{ sm: '1.06rem', xs: '1rem' }}
							>
								{props.questions.length - 1} Questions
							</Typography>
						</Stack>
					</Stack>
					<Box display="flex" justifyContent="end">
						<Button
							sx={{
								borderRadius: '10px 2px 10px 2px',
								padding: '4px 20px',
								fontWeight: 900,
							}}
							variant="outlined"
							disabled={!studentaccess}
							onClick={createInterview}
						>
							Start
						</Button>
					</Box>
				</Stack>
			</Box>
		</Stack>
	);
};

export default Interview;
