import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { Pagination, Navigation } from 'swiper/modules';
import VideoPlayer from './VideoPlayer';
export default function VideosList({ questionVideos, questions }) {
	console.log(questionVideos, 'question videos', questions, 'questions');
	if (!questionVideos || !questions) return <></>;
	return (
		<Swiper
			pagination={{ type: 'fraction' }}
			navigation={true}
			modules={[Pagination, Navigation]}
			className="mySwiper"
			style={{ width: '600px', pointerEvents: 'auto' }}
		>
			{questionVideos?.map((video, index) => (
				<SwiperSlide key={index}>
					<div style={{ textAlign: 'center', height: '100px' }}>
						Q
						{index +
							1 +
							'. ' +
							(questions[index]?.question_text ??
								questions[index]?.questions[0]?.question_text)}
					</div>
					<div className="video-container">
						<VideoPlayer questionVideo={video.videoUrl} diff={video.diff} />
					</div>
				</SwiperSlide>
			))}
		</Swiper>
	);
}
