import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import ReactPlayer from 'react-player';
import { AudioRecorder } from '../AudioRecorder';
import { useLocation } from 'react-router';
import { Header } from '../shared/Header';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import { QuestionPopper } from '../QuestionPopper';
import { CountdownTimer } from '../CountdownTimer';
import { VideoAndScreenRecorder } from '../VideoAndScreenRecorder';
import { BrowserView, MobileView } from 'react-device-detect';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { ReplayButton } from '../ReplayButton';
import { useNavigate, useParams } from 'react-router-dom';

// Dialog to prevent user from leaving the page
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { InterviewContext } from '../../global/InterviewContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectInterviewData } from '../../features/interview/interview';
import { PROCTORING_TOOL } from '../../global/constants';
const datetostring = (currentDate) => {
	const year = currentDate.getFullYear();
	const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
	const day = String(currentDate.getDate()).padStart(2, '0');
	const hours = String(currentDate.getHours()).padStart(2, '0');
	const minutes = String(currentDate.getMinutes()).padStart(2, '0');
	const seconds = String(currentDate.getSeconds()).padStart(2, '0');

	// Construct the date-time string
	const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	return dateTimeString;
};

export const InterviewTrial = () => {
	const navigate = useNavigate();

	const Interview_started_time = new Date();
	const { setInterviewEnded } = useContext(InterviewContext);
	const interview_data = useSelector(selectInterviewData);
	const { fullName, isDemo, isStudentInterview, candidate_interview, proctoring_token } =
		interview_data;
	const { proctoring_tool } = candidate_interview;
	const [isproctoringCheck, setisproctoringcheck] = useState(false);
	const reactPlayerRef = useRef(null);
	const introVideoRef = useRef(null);
	const [error, setError] = useState(false);
	const [videoUrl, setVideoUrl] = useState('');
	const [mobileVideoUrl, setMobileVideoUrl] = useState('');
	const [mobileIntroVideo, setMobileIntroVideo] = useState('');
	const [desktopIntroVideo, setDesktopIntroVideo] = useState('');
	const [hasIntroVideoEnded, setHasIntroVideoEnded] = useState(false);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [isListening, setIsListening] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const { interviewtype } = useParams();

	const { state } = useLocation();
	console.log(state, 'interview state');

	const { _id: interviewKey } = candidate_interview;
	const [questionTimestamps, setQuestionTimestamps] = useState([]);
	const [loading, setLoading] = useState(true);
	const [hasInterviewEnded, setHasInterviewEnded] = useState(false);
	const [listeningTimestamp, setListeningTimestamp] = useState({
		start_time: 0,
		end_time: 0,
	});
	const [start, setStart] = useState(false);
	const [questionsArray, setQuestionsArray] = useState([]);

	const [open, setOpen] = useState(true);
	const [stopInterview, setStopInterview] = useState(false);

	const handleClose = () => setOpen(false);

	const [playing, setPlaying] = useState(false);
	const [playsinline, setPlaysinline] = useState(false);
	const [isIphone, setIsIphone] = useState(false);
	const [showReplayButton, setShowReplayButton] = useState(false);
	const [interviewStartedTime, setInterviewStartedTime] = useState(() => new Date());
	const [answeringTime, setAnsweringTime] = useState('');
	const [showCountdownTimer, setShowCountdownTimer] = useState(false);
	const [desktopPlaying, setDesktopPlaying] = useState(true);
	const [desktopPlaysinline, setDesktopPlaysinline] = useState(true);
	const [questionHints, setQuestionHints] = useState([]);
	// For preventing user from leaving the page
	const [alertDialogOpen, setAlertDialogOpen] = useState(false);
	// const [tabSwitches, setTabSwitches] = useState(0);
	let tabSwitches = 0;
	let timer;

	useEffect(() => {
		if (alertDialogOpen) {
			timer = setInterval(() => {
				endUserInterview('violation', tabSwitches);
			}, [15000]);
		} else {
			clearInterval(timer);
		}
		return () => clearInterval(timer);
	}, [alertDialogOpen]);

	const exitRefresh = useCallback((e) => {
		e.preventDefault();
		e.returnValue = '';
	});
	const checkTabSwitches = useCallback(() => {
		if (!hasInterviewEnded && proctoring_tool !== PROCTORING_TOOL) {
			setAlertDialogOpen(true);
			setPlaying(false);
			setPlaysinline(false);
			setDesktopPlaying(false);
			setDesktopPlaysinline(false);
			tabSwitches++;
			if (tabSwitches > 3) {
				setAlertDialogOpen(false);
				// console.log("You have switched tabs more than 3 times.");
				if (
					!isStudentInterview &&
					!proctoring_token &&
					proctoring_tool !== PROCTORING_TOOL
				) {
					alert('You violated the terms of the interview. The interview has been ended.');
					endUserInterview();
					setAlertDialogOpen(false);
				}
			}
		}
	});
	useEffect(() => {
		console.log(proctoring_tool);
		if (proctoring_token) {
			console.log('loading ...');
			const jwtToken = proctoring_token;
			window.remoteProctoring
				.remoteProctorInitV2({ authToken: jwtToken })
				.then(function () {
					console.log('Initialization is success');
					setisproctoringcheck(true);
				})
				.catch(function (err) {
					console.log('Initialization failed', err);
					alert('Initialization failed');
				});

			console.log('loaded');
		}
	}, [proctoring_token]);
	useEffect(() => {
		// if (!videoUploaded) {
		//     window.addEventListener("beforeunload", exitRefresh);
		// } else {
		//     window.removeEventListener("beforeunload", exitRefresh);
		// }

		if (proctoring_tool === PROCTORING_TOOL) {
			window.remoteProctoring
				.stop()
				.then(function () {
					navigate(`/end/${fullName}/${interviewKey}`, {
						state: {
							isDemo: false,
							isStudentInterview,
						},
					});
					console.log('Remote Proctoring session ended successfully');
				})
				.catch(function (err) {
					console.log('Remote Proctoring session end FAILED', err);
				});
		} else {
			if (hasInterviewEnded && !loading) {
				console.log(hasInterviewEnded, 'xyz has interview ended ', loading);
				navigate(`/end/${fullName}/${interviewKey}`);
			}
			if (!hasInterviewEnded) {
				window.addEventListener('blur', checkTabSwitches);
			} else {
				window.removeEventListener('blur', checkTabSwitches);
			}
			return () => {
				window.removeEventListener('beforeunload', exitRefresh);
				window.removeEventListener('blur', checkTabSwitches);
			};
		}
	}, [hasInterviewEnded]);

	useEffect(() => {
		if (
			navigator.platform === 'iPad' ||
			navigator.platform === 'iPhone' ||
			navigator.platform === 'iPod'
		) {
			console.log('This is an iOS device.');
			setIsIphone(true);
		} else {
			console.log('This is not an iOS device!');
			setIsIphone(false);
		}
	}, []);

	const restartPlaying = () => {
		setPlaying(true);
		setPlaysinline(true);
		setDesktopPlaying(true);
		setDesktopPlaysinline(true);
		setAlertDialogOpen(false);
		clearInterval(timer);
	};
	useEffect(() => {
		if (mobileIntroVideo && desktopIntroVideo && introVideoRef && introVideoRef.current) {
			introVideoRef.current.playing = true;
			introVideoRef.current.playsinline = true;
			introVideoRef.current.pip = false;
		}
	}, [mobileIntroVideo, desktopIntroVideo]);

	useEffect(() => {
		fetchQuestions(interviewKey);
	}, [interviewKey]);

	useEffect(() => {
		if (reactPlayerRef && reactPlayerRef.current) {
			reactPlayerRef.current?.seekTo(
				questionTimestamps[currentQuestionIndex].startTime,
				'seconds'
			);
			setIsRecording(false);
			reactPlayerRef.current.playing = true;
			reactPlayerRef.current.playsinline = true;
			reactPlayerRef.current.pip = false;
		}
		if (currentQuestionIndex === questionTimestamps.length - 1) {
			updateInterviewEnded();
		}
	}, [currentQuestionIndex]);
	const endUserInterview = async (endInterviewReason, tab_switches) => {
		await axios.post(`${API_URL}/labs/interview/end`, {
			interview_key: interviewKey,
			end_interview_reason: endInterviewReason,
			tab_switches,
		});
		setPlaying(false);
		setPlaysinline(false);
		setDesktopPlaying(false);
		setDesktopPlaysinline(false);
		setHasInterviewEnded(true);
		setInterviewEnded(true);
		// setUploadVideo(true);
	};

	const fetchQuestions = async (key) => {
		setLoading(true);
		const res = await axios.get(`${API_URL}/labs/questions?interview_key=${key}`);
		console.log(res, 'xyz result questions');
		setQuestionTimestamps(res?.data?.data?.timestamps);
		setVideoUrl(res.data.data.video_link);
		setMobileVideoUrl(res.data.data.mobile_video_link);
		setListeningTimestamp(res.data.data.listening_timestamp);
		setMobileIntroVideo(res?.data?.data?.mobile_intro_video);
		setDesktopIntroVideo(res?.data?.data?.desktop_intro_video);
		setQuestionsArray(res?.data?.data?.questions);
		setQuestionHints(res?.data?.data?.question_hints);
		// const questions = res.data.data.
		console.log(res.data.data);
		if (
			!res?.data?.data?.mobile_intro_video ||
			!res?.data?.data?.desktop_intro_video ||
			res?.data?.data?.mobile_intro_video === '' ||
			res?.data?.data?.desktop_intro_video === ''
		) {
			setHasIntroVideoEnded(true);
			setShowCountdownTimer(true);
		}
		if (res.data.data.start_with !== 0) {
			setHasIntroVideoEnded(true);
			setShowCountdownTimer(true);
		}
		setLoading(false);
		console.log(res.data.data.start_with, 'start with');
		setCurrentQuestionIndex(res.data.data.start_with);
		reactPlayerRef.current?.seekTo(questionTimestamps[res.data.data.start_with].startTime);
	};

	const handlePostQuestionAction = () => {
		if (!isListening && currentQuestionIndex < questionTimestamps.length - 1) {
			setIsListening(true);
			handleListeningLoop();
		}
	};

	const handleProgress = ({ playedSeconds }) => {
		if (!start) {
			reactPlayerRef.current?.seekTo(questionTimestamps[currentQuestionIndex].startTime);
			setStart(true);
		}
		if (
			!isListening &&
			currentQuestionIndex !== questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].endTime
		) {
			setIsRecording(true);
			setShowReplayButton(true);
			handlePostQuestionAction();
		}

		if (
			currentQuestionIndex >= questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].startTime &&
			!updateAnswerToDatabase
		) {
			setIsListening(false);
			setIsRecording(false);
			setShowReplayButton(false);
		}
		if (
			currentQuestionIndex === questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].endTime
		) {
			setHasInterviewEnded(true);
			setInterviewEnded(true);
			setIsListening(false);
			setIsRecording(false);
		}

		if (isListening && playedSeconds >= listeningTimestamp.end_time) {
			reactPlayerRef.current?.seekTo(listeningTimestamp.start_time, 'seconds');
		}
	};

	const handleEnded = () => {
		if (currentQuestionIndex < questionTimestamps.length - 1) {
			handlePostQuestionAction();
		} else {
			setHasInterviewEnded(true);
			setInterviewEnded(true);
			// setUploadVideo(true);
		}
	};

	const handleListeningLoop = () => {
		reactPlayerRef.current?.seekTo(listeningTimestamp.start_time, 'seconds');
	};

	const goToNextQuestion = () => {
		if (currentQuestionIndex >= questionTimestamps.length - 1) {
			setHasInterviewEnded(true);
			setInterviewEnded(true);
			// setUploadVideo(true);
			updateInterviewEnded();
			setIsListening(false);
			setIsRecording(false);
		} else {
			setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
		}
	};

	const updateIsListening = (value) => {
		setShowReplayButton(false);
		setIsListening(value);
		if (value) {
			handleListeningLoop();
		} else {
			goToNextQuestion();
		}
	};
	const updateInterviewEnded = async () => {
		await axios.post(`${API_URL}/labs/interview/end`, {
			interview_key: interviewKey,
			tabSwitches,
		});
	};

	const updateAnswerToDatabase = async (s3FileName, file) => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('audioLinkKey', s3FileName);
			formData.append('interviewKey', interviewKey);
			formData.append('questionId', currentQuestionIndex);
			formData.append('answeringTime', answeringTime);
			formData.append('startedDate', datetostring(interviewStartedTime));
			formData.append('interview_type', interviewtype);
			const time_spent = new Date() - interviewStartedTime;
			console.log(time_spent, 'time spent');
			//time_spent tells how much spent on current sesssion time.
			formData.append('time_spent', time_spent);
			const response = await axios.post(`${API_URL}/labs/saveCandidateAnswer`, formData);
			if (currentQuestionIndex === questionTimestamps.length - 2) {
				const response = await axios.get(`${API_URL}/labs/scores/evaluate/${interviewKey}`);
				console.log(response.data);
				//   if (response) {
				//     setUploadVideo(true);
				//   }
			}
		} catch (error) {
			setError(true);
		}
	};

	const startInterview = () => {
		handleClose();
		setPlaying(true);
		setPlaysinline(true);
	};

	const replayQuestion = () => {
		reactPlayerRef.current?.seekTo(questionTimestamps[currentQuestionIndex].startTime);
		setPlaying(true);
		setIsListening(false);
		setIsRecording(false);
		setShowReplayButton(false);
	};

	const handleStopInterview = () => {
		setStopInterview(true);
	};

	const handleRestartInterview = () => {
		navigate('/login/candidate');
	};

	const handleIntroVideoEnded = () => {
		setHasIntroVideoEnded(true);
		setShowCountdownTimer(true);
	};

	const removeCountdownTimer = () => {
		setShowCountdownTimer(false);
	};
	if (proctoring_token && !isproctoringCheck) {
		return <></>;
	}
	console.log(videoUrl, playing, 'xyz video url');

	return (
		<>
			<Dialog open={alertDialogOpen && !proctoring_token}>
				<DialogTitle display="flex" alignItems={'center'}>
					<WarningAmberIcon color="red" />
					<b>Warning</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						We noticed that you moved out of the interview.{' '}
						<b>The hiring team is tracking</b> this activity. We recommend staying on
						this page until you complete the interview.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						style={{
							textTransform: 'none',
							color: '#fff',
							backgroundColor: 'green',
						}}
						onClick={restartPlaying}
					>
						Agree & Continue
					</Button>
				</DialogActions>
			</Dialog>
			{isIphone ? (
				<>
					<Header fullName={fullName} />
					<Box
						p={3}
						display="flex"
						style={{ height: '85vh' }}
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="body2" fontWeight="bold" textAlign="center">
							The interview experience is not available on iPhones. Please use chrome
							or Firefox on your desktop to complete the interview.
						</Typography>
					</Box>
				</>
			) : (
				<div>
					{stopInterview ? (
						<div>
							<p>
								In order to give this interview, you will have to share your Entire
								Screen.
							</p>
							<p>Please press Back Button and restart the interview.</p>
							<Button
								variant="contained"
								onClick={handleRestartInterview}
								sx={{ textTransform: 'none', marginTop: 3 }}
							>
								Restart Interview
							</Button>
						</div>
					) : (
						<>
							<MobileView>
								<div>
									<Modal
										open={open}
										// onClose={handleClose}
										aria-labelledby="modal-modal-title"
										aria-describedby="modal-modal-description"
									>
										<Stack
											justifyContent="center"
											alignItems="center"
											sx={{
												position: 'absolute',
												top: '50%',
												left: '50%',
												transform: 'translate(-50%, -50%)',
												width: '400px',
												p: 4,
											}}
										>
											<Button
												variant="contained"
												onClick={startInterview}
												p={0}
												sx={{ width: '175px' }}
											>
												Start Interview
											</Button>
										</Stack>
									</Modal>
									{!hasInterviewEnded && <Header fullName={fullName} />}
									{loading && !hasInterviewEnded && <CircularStatic />}
									{!loading && (
										<>
											{!hasInterviewEnded &&
												hasIntroVideoEnded &&
												!showCountdownTimer && (
													<ReactPlayer
														ref={reactPlayerRef}
														url={mobileVideoUrl}
														// controls
														playing={playing}
														playsinline={playsinline}
														width="100%"
														height="90vh"
														progressInterval={1000}
														onProgress={handleProgress}
														onEnded={handleEnded}
														pip={false}
														onContextMenu={(e) => e.preventDefault()}
														config={{
															file: {
																attributes: {
																	onContextMenu: (e) =>
																		e.preventDefault(),
																},
															},
														}}
													/>
												)}
											{/* <VideoAndScreenRecorder
												interviewKey={interviewKey}
												isStudentInterview={isStudentInterview}
												fullName={fullName}
												isDemo={isDemo}
												currentQuestionIndex={currentQuestionIndex}
											/> */}
										</>
									)}
									{!loading &&
										!!mobileIntroVideo &&
										!hasIntroVideoEnded &&
										!hasInterviewEnded && (
											<ReactPlayer
												ref={introVideoRef}
												playing
												playsinline
												onEnded={handleIntroVideoEnded}
												width="100%"
												height="90vh"
												url={mobileIntroVideo}
												onContextMenu={(e) => e.preventDefault()}
												config={{
													file: {
														attributes: {
															onContextMenu: (e) =>
																e.preventDefault(),
														},
													},
												}}
											/>
										)}
									{showCountdownTimer && (
										<CountdownTimer
											removeCountdownTimer={removeCountdownTimer}
											timerText={'Interview starts in ...'}
										/>
									)}
									{questionsArray &&
										questionsArray.length > 0 &&
										currentQuestionIndex !== questionsArray.length - 1 &&
										!loading &&
										!hasInterviewEnded &&
										hasIntroVideoEnded &&
										!showCountdownTimer && (
											<Stack
												direction="row"
												width={'90vw'}
												display={'flex'}
												justifyContent={'center'}
												alignItems={'center'}
												position={'absolute'}
												top={'90px'}
												right={'20px'}
												left={'20px'}
											>
												<QuestionPopper
													questionTitle={`Question ${
														currentQuestionIndex + 1
													} of ${questionsArray.length - 1}`}
													questionText={
														questionsArray[currentQuestionIndex]
													}
													questionHint={
														questionHints.filter(
															(hint) =>
																hint.question_id ===
																currentQuestionIndex
														)[0]
													}
												/>
											</Stack>
										)}
									<Stack
										direction="row"
										width={'100vw'}
										display={'flex'}
										justifyContent={'center'}
										alignItems={'center'}
										position={'absolute'}
										bottom={0}
										gap={'20px'}
									>
										{isRecording && !hasInterviewEnded && (
											<AudioRecorder
												interviewKey={interviewKey}
												handleAnswer={updateAnswerToDatabase}
												updateIsListening={updateIsListening}
												questionId={currentQuestionIndex}
												candidateName={fullName}
												isPractice={false}
												enabledRecording={isRecording}
												setAnsweringTime={setAnsweringTime}
											/>
										)}
										{showReplayButton && (
											<ReplayButton replayQuestion={replayQuestion} />
										)}
									</Stack>
								</div>
							</MobileView>
							<BrowserView>
								{!hasInterviewEnded && <Header fullName={fullName} />}
								{loading && !hasInterviewEnded && <CircularStatic />}
								{!loading && (
									<>
										{!hasInterviewEnded &&
											hasIntroVideoEnded &&
											!showCountdownTimer && (
												<ReactPlayer
													ref={reactPlayerRef}
													url={videoUrl}
													// controls
													playing={desktopPlaying}
													playsinline={desktopPlaysinline}
													width="100%"
													height="90vh"
													progressInterval={1000}
													onProgress={handleProgress}
													onEnded={handleEnded}
													pip={false}
													onContextMenu={(e) => e.preventDefault()}
													config={{
														file: {
															attributes: {
																onContextMenu: (e) =>
																	e.preventDefault(),
															},
														},
													}}
												/>
											)}
										<VideoAndScreenRecorder
											interviewKey={interviewKey}
											isStudentInterview={isStudentInterview}
											fullName={fullName}
											isDemo={isDemo}
											currentQuestionIndex={currentQuestionIndex}
											endUserInterview={endUserInterview}
										/>
									</>
								)}
								{!loading &&
									!!desktopIntroVideo &&
									!hasIntroVideoEnded &&
									!hasInterviewEnded && (
										<ReactPlayer
											ref={introVideoRef}
											playing
											playsinline
											onEnded={handleIntroVideoEnded}
											width="100%"
											height="90vh"
											url={desktopIntroVideo}
											pip={false}
											onContextMenu={(e) => e.preventDefault()}
											config={{
												file: {
													attributes: {
														onContextMenu: (e) => e.preventDefault(),
													},
												},
											}}
										/>
									)}
								{showCountdownTimer && (
									<CountdownTimer
										removeCountdownTimer={removeCountdownTimer}
										timerText={'Interview starts in ...'}
									/>
								)}
								{questionsArray &&
									questionsArray.length > 0 &&
									currentQuestionIndex !== questionsArray.length - 1 &&
									!loading &&
									!hasInterviewEnded &&
									hasIntroVideoEnded &&
									!showCountdownTimer && (
										<Stack
											direction="row"
											width={'100vw'}
											display={'flex'}
											justifyContent={'left'}
											alignItems={'left'}
											position={'absolute'}
											left={'20px'}
											top={'80px'}
										>
											<QuestionPopper
												questionTitle={`Question ${
													currentQuestionIndex + 1
												} of ${questionsArray.length - 1}`}
												questionText={questionsArray[currentQuestionIndex]}
												questionHint={
													questionHints.filter(
														(hint) =>
															hint.question_id ===
															currentQuestionIndex
													)[0]
												}
											/>
										</Stack>
									)}
								<Stack
									direction="row"
									width={'100vw'}
									display={'flex'}
									justifyContent={'center'}
									alignItems={'center'}
									position={'absolute'}
									bottom={'80px'}
									gap={'20px'}
								>
									{isRecording && !hasInterviewEnded && (
										<AudioRecorder
											interviewKey={interviewKey}
											handleAnswer={updateAnswerToDatabase}
											updateIsListening={updateIsListening}
											questionId={currentQuestionIndex}
											candidateName={fullName}
											isPractice={false}
											enabledRecording={isRecording}
											setAnsweringTime={setAnsweringTime}
										/>
									)}
									{showReplayButton && (
										<ReplayButton replayQuestion={replayQuestion} />
									)}
								</Stack>
							</BrowserView>
							{/* <EndScreen interviewKey={interviewKey} fullName={fullName} hasVideoUploaded=} /> */}
						</>
					)}
				</div>
			)}
		</>
	);
};
