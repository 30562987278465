import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../shared/Header';
import { Stack } from '@mui/system';
import { Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Interview from './Interview';
import { useLocation } from 'react-router';
const AllInterviews = () => {
	const [interviews, setInterviews] = useState([]);
	const [loading, setLoading] = useState(false);
	const { state } = useLocation();
	const { fullName } = state;
	const getAllInterviews = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student-interview/degree`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			setInterviews(data.data);
			if (data.success) {
				console.log(data);
				// toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};
	useEffect(() => {
		getAllInterviews();
	}, []);
	return (
		<>
			<Toaster />
			<Header />
			<Stack
				p={{ sm: 5, xs: 3 }}
				spacing={{ sm: 5, xs: 3 }}
				style={{ backgroundColor: '#ecf7fa' }}
			>
				<Typography fontWeight={'bold'} fontSize={'1.3rem'}>
					All Interviews
				</Typography>
				<Stack
					direction={'row'}
					useFlexGap
					flexWrap={'wrap'}
					spacing={5}
					// columnGap={5}
					// rowGap={2}
					// justifyContent={"space-between"}
					alignItems={'center'}
				>
					{loading && (
						<Stack
							p={{ sm: 3, xs: 2 }}
							bgcolor={'#fff'}
							spacing={2}
							width={{ sm: '400px', xs: '100%' }}
							height={'auto'}
							borderRadius={'20px'}
							// border={"2px solid #cccccc"}
							boxShadow={'8px 6px 12px 0 rgba(13,31,61,.04)'}
						>
							<Toaster />
							<Typography fontWeight={900} fontSize={'1.5rem'}>
								<Skeleton
									variant="text"
									width={'250px'}
									// width={{ sm: "300px", xs: "250px" }}
								/>
							</Typography>
							<Typography fontSize={'1rem'} color={'#cccccc'}>
								<Skeleton variant="text" width={'200px'} />
							</Typography>
							<Skeleton variant="text" />
							<Skeleton variant="text" />
							<Skeleton variant="text" />
							<Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
								<Stack
									direction={'row'}
									spacing={1}
									justifyContent={'center'}
									alignItems={'center'}
								>
									<SignalCellularAltIcon
										style={{
											color: '#cccccc',
										}}
									/>
									<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
										<Skeleton variant="text" width={'15px'} />
									</Typography>
								</Stack>
								<Stack
									direction={'row'}
									spacing={1}
									justifyContent={'center'}
									alignItems={'center'}
								>
									<LiveHelpIcon
										style={{
											color: '#cccccc',
										}}
									/>
									<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
										<Skeleton variant="text" width={'15px'} />{' '}
									</Typography>
								</Stack>
							</Stack>
							<Skeleton variant="button" width={'100px'} />
						</Stack>
					)}
					{!loading && interviews.length === 0 && (
						<Typography>No interviews available for you.</Typography>
					)}
					{!loading &&
						interviews.map((interview) => (
							<Interview
								interviewDescription={interview?.description}
								key={interview._id}
								interview_id={interview._id}
								role={interview.role}
								industry={interview.industry}
								interview_level={interview.interview_level}
								questions={interview.questions}
								fullName={fullName}
							/>
						))}
				</Stack>
			</Stack>
		</>
	);
};

export default AllInterviews;
