import React, { useEffect, useState, useRef, useContext } from 'react';
import RecordRTC from 'recordrtc';
import { Toaster } from 'react-hot-toast';
import './style.css';
import { useMediaQuery } from 'react-responsive';
import Draggable from 'react-draggable';
import { EndScreen } from '../EndScreen';
import WebcamStream from '../WebcamStream';
import { InterviewContext } from '../../global/InterviewContext';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { API_URL } from '../../global/constants';

export const VideoAndScreenRecorder = (props) => {
	const { interviewKey, fullName, isStudentInterview, currentQuestionIndex, endUserInterview } =
		props;
	const navigate = useNavigate();
	const { interviewEnded } = useContext(InterviewContext);
	const [recordingStarted, setRecordingStarted] = useState(false);
	const [cameraStream, setCameraStream] = useState();
	const recorderRef = useRef(null);
	const [showLastScreen, setShowLastScreen] = useState(false);
	const [hasVideoUploaded, setHasVideoUploaded] = useState(false);
	const [percentage, setPercentage] = useState(0);
	const isMobile = useMediaQuery({ maxWidth: 500 });
	const [videoStyle, setVideoStyle] = useState({});
	const [divStyle, setDivStyle] = useState({});
	const { interviewtype } = useParams();
	const [isScreenSharingEnabled, setIsScreenSharingEnabled] = useState(false);
	const [date, setDate] = useState(new Date());
	const [dragState, setDragState] = useState({
		activeDrags: 0,
		deltaPosition: {
			x: 0,
			y: 0,
		},
		controlledPosition: {
			x: -400,
			y: 200,
		},
	});
	const onStart = () => {
		setDragState({ ...dragState, activeDrags: ++dragState.activeDrags });
	};
	const onStop = () => {
		setDragState({ ...dragState, activeDrags: --dragState.activeDrags });
	};
	const startRecording = () => {
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: true })
			.then(function (stream) {
				const video = document.querySelector('#videoElement');
				video.srcObject = stream;
				setCameraStream(stream);
				video.play();
				video.muted = true;

				recorderRef.current = new RecordRTC(stream, { type: 'video' });
				recorderRef.current.startRecording();
				setRecordingStarted(true);
				window.videoRecorderRef = recorderRef;
				window.videoStream = stream;
			})
			.catch(function (error) {
				console.error('Cannot access media devices: ', error);
			});
	};

	const uploadVideo = (blob, diff) => {
		const formData = new FormData();
		formData.append('video', blob, 'video.mp4');
		formData.append('currentQuestionIndex', currentQuestionIndex);
		formData.append('interviewKey', interviewKey);
		formData.append('diff', diff);

		axios
			.post(`${API_URL}/labs/upload-question-videos`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				console.log('Upload successful', response);
			})
			.catch((error) => {
				console.error('Upload failed', error);
			});
	};
	useEffect(() => {
		console.log(`Current question index has changed: ${currentQuestionIndex}`);
		if (recordingStarted) {
			recorderRef.current.stopRecording(() => {
				const endTime = new Date();
				const diff = (endTime - date) / 1000;
				console.log('End time:', endTime);
				console.log('Difference in seconds:', diff);
				setDate(new Date());
				const blob = recorderRef.current.getBlob();
				uploadVideo(blob, diff);

				cameraStream.getTracks().forEach((track) => track.stop());
				setRecordingStarted(false);
			});
		}
	}, [currentQuestionIndex]);

	useEffect(() => {
		if (isMobile) {
			setDivStyle({
				position: 'absolute',
				width: 'fit-content',
				right: '10px',
				top: '80px',
				float: 'right',
			});
			setVideoStyle({
				position: 'relative',
				width: '150px',
				height: 'auto',
				borderRadius: '22px',
				border: '2px solid #cccccc',
				overflow: 'hidden',
				WebkitTransform: 'translateZ(0)',
				boxShadow: '0 19px 51px 0 rgba(0,0,0,0.16), 0 14px 19px 0 rgba(0,0,0,0.07)',
			});
		} else {
			setDivStyle({
				position: 'absolute',
				right: '10vh',
				bottom: '50px',
				width: 'fit-content',
				// left: "10px",
				// top: "80px",
				float: 'right',
				zIndex: 2,
			});
			setVideoStyle({
				position: 'relative',
				width: '300px',
				height: 'auto',
				borderRadius: '22px',
				border: '4px solid #cccccc',
				overflow: 'hidden',
				WebkitTransform: 'translateZ(0)',
				boxShadow: '0 19px 51px 0 rgba(0,0,0,0.16), 0 14px 19px 0 rgba(0,0,0,0.07)',
			});
		}
		// const startRecording = () => {
		// 	navigator.mediaDevices
		// 		.getUserMedia({
		// 			audio: true,
		// 			video: true,
		// 		})
		// 		.then(function (stream) {
		// 			const video = document.querySelector('#videoElement');
		// 			// Display a live preview on the video element of the page
		// 			video.srcObject = stream;
		// 			setCameraStream(stream);

		// 			// Start to display the preview on the video element
		// 			// and mute the video to disable the echo issue !
		// 			video.play();
		// 			video.muted = true;

		// 			recorderRef.current = new RecordRTC(stream, {
		// 				type: 'video',
		// 			});
		// 			recorderRef.current.startRecording();
		// 			setRecordingStarted(true);
		// 			window.videoRecorderRef = recorderRef;
		// 			window.videoStream = stream;
		// 		})
		// 		.catch(function (error) {
		// 			console.log(error);
		// 			console.error('Cannot access media devices: ', error);
		// 		});
		// };

		if (!recordingStarted) {
			startRecording();
		}
		const checkWebcamStream = async () => {
			try {
				const constraints = { video: true };
				const stream = await navigator.mediaDevices.getUserMedia(constraints);

				if (stream && stream.getVideoTracks().length > 0) {
					console.log('Webcam stream is valid.');
				} else {
					console.log('Stream has no video tracks, considered invalid.');
				}
			} catch (e) {
				let text = '';
				if (e.name === 'NotAllowedError') {
					console.log('Permission denied by user or system.');
					text = 'Permission denied by user or system.';
				} else if (e.name === 'NotFoundError') {
					console.log('No webcam found.');
					text = 'No webcam found.';
				} else if (e.name === 'NotReadableError') {
					console.log('Webcam is already in use.');
					text = 'Webcam is already in use.';
				} else {
					console.log('Error accessing webcam:', e);
					text = e;
				}
				endUserInterview(text, 0);
				navigate(`/end/violation/${fullName}/${interviewKey}`, {
					state: { reason: text },
				});
			}
		};

		// Call the function once to check the webcam stream
		checkWebcamStream();
	}, [recordingStarted]);

	const mountTime = new Date(); // Record the time when the component is mounted

	return (
		<>
			<Toaster />
			{/* <Modal open={hasInterviewEnded && show}> */}
			{interviewEnded && showLastScreen && (
				<EndScreen
					interviewKey={interviewKey}
					hasVideoUploaded={hasVideoUploaded}
					percentage={percentage}
				/>
			)}

			{!interviewEnded && (
				<Draggable onStart={onStart} onStop={onStop} bounds="parent">
					<div id="container" style={divStyle}>
						{!interviewEnded && (
							<video
								style={{ display: 'none' }}
								autoPlay
								playsInline
								id="videoElement"
							></video>
						)}
						{!interviewEnded && (
							<WebcamStream
								style={videoStyle}
								interviewEnded={interviewEnded}
								interviewKey={interviewKey}
								isStudentInterview={isStudentInterview}
							/>
						)}
						{/* <canvas id="canvas" width="400px" height="300px"></canvas> */}
					</div>
				</Draggable>
			)}
		</>
	);
};
