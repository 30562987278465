export const demoMapping = {
	development: {
		1234: '6548b2da82e8d7c570309834',
		5678: '65462633a21c2fd6e8796d6d',
		A123: '6548ab9f82e8d7c570309802',
		A456: '',
		A789: '',
		B123: '',
		B456: '',
		B789: '',
		C123: '',
		C456: '',
		C789: '',
		D123: '',
		D456: '',
		D789: '',
	},
	production: {
		A123: '6548f7aa7d1150114f548d1c',
		A456: '6548f7ce7d1150114f548d39',
		A789: '6548f7eb7d1150114f548d56',
		B123: '6548f85e7d1150114f548d73',
		B456: '6548f8797d1150114f548d90',
		B789: '6548f8987d1150114f548dad',
		C123: '6548f8b87d1150114f548dca',
		C456: '6548f8cf7d1150114f548de7',
		C789: '6548f8f37d1150114f548e04',
		D123: '6548f9247d1150114f548e21',
		D456: '6548f99c7d1150114f548e3e',
		D789: '6548f9b87d1150114f548e5b',
	},
};
