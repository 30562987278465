import ClearIcon from '@mui/icons-material/Clear';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Chip, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../global/constants';
import { Header } from '../shared/Header';

const ResumeEvaluation = () => {
	const { state } = useLocation();
	const { jobRole, jobId, companyId } = state;
	const selectResumesRef = useRef(null);
	const [resumes, setResumes] = useState([]);
	const [source, setSource] = useState('None');
	const [resumeSuccessfullyUploaded, setResumeSuccessfullyUploaded] = useState(false);
	const [uploadResumeLoading, setUploadResumeLoading] = useState(false);
	const handleResumeDelete = (i) => {
		return () => {
			setResumes((resumes) => resumes.filter((resume, index) => index !== i));
			console.log(resumes);
		};
	};
	const uploadResumes = async () => {
		setUploadResumeLoading(true);
		setResumeSuccessfullyUploaded(false);
		const formData = new FormData();
		for (let i = 0; i < resumes.length; i++) {
			formData.append(resumes[i].name, resumes[i]);
		}
		// formData.append("job_id", jobId);
		// formData.append("company_id", companyId);
		console.log('formData', formData);
		console.log('resumes', resumes);
		const { data } = await axios.post(
			`${API_URL}/labs/evaluate/resumes/${companyId}/${jobId}/${source}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			}
		);
		console.log(data);
		if (data.success) {
			toast.success(data.message);
			setResumeSuccessfullyUploaded(true);
			setResumes([]);
		} else {
			toast.error(data.message);
		}
		setUploadResumeLoading(false);
	};
	return (
		<Stack justifyContent={'center'} alignItems={'center'}>
			<Toaster />
			<Header />
			<Typography mt={3} fontSize={'1.5rem'} textAlign={'center'} fontWeight={'bold'}>
				Upload resume for evaluations
				<br /> for {jobRole}
			</Typography>

			<>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'center'}
					alignItems={'center'}
					spacing={1}
					mt={2}
				>
					<FormControl size="small" sx={{ width: '150px' }}>
						<InputLabel>Source</InputLabel>
						<Select
							value={source}
							label="Source"
							onChange={(e) => {
								setSource(e.target.value);
							}}
						>
							<MenuItem value={'None'}>None</MenuItem>

							<MenuItem value={'LinkedIn'}>LinkedIn</MenuItem>
							<MenuItem value={'Naukri'}>Naukri</MenuItem>
							<MenuItem value={'Hirist'}>Hirist</MenuItem>
							<MenuItem value={'SmartInterviews'}>SmartInterviews</MenuItem>
							<MenuItem value={'Instahyre'}>Instahyre</MenuItem>
							<MenuItem value={'QSpiders-JSpiders'}>QSpiders-JSpiders</MenuItem>
							<MenuItem value={'Nxtwave'}>Nxtwave</MenuItem>
							<MenuItem value={'Masai'}>Masai</MenuItem>
							<MenuItem value={'iimJobs'}>iimJobs</MenuItem>
							<MenuItem value={'College'}>None</MenuItem>
							<MenuItem value={'Others'}>Others</MenuItem>
							{/* <MenuItem value={30}>Thirty</MenuItem> */}
						</Select>
					</FormControl>
					<Button
						variant="outlined"
						onClick={() => selectResumesRef.current.click()}
						style={{
							textTransform: 'none',
						}}
						sx={{
							textTransform: 'none',
							color: '#224C84',
							'&:hover': {
								backgroundColor: '#224C84',
								color: 'white',
								border: '1px solid #224C84',
							},
							border: '1px solid #224C84',
						}}
						startIcon={<UploadFileIcon />}
					>
						{' '}
						Select Resumes
					</Button>
					<Typography fontSize={'0.9rem'}>{resumes.length} Resumes Selected</Typography>
				</Stack>
				<input
					ref={selectResumesRef}
					type="file"
					accept="application/pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					onChange={(e) => {
						setResumes([...resumes, ...Array.from(e.target.files)]);
						console.log(Array.from(e.target.files));
					}}
					style={{ display: 'none' }}
					multiple
				/>
				{resumes.length > 0 && (
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={1}
						p={2}
						useFlexGap
						flexWrap="wrap"
						alignItems={'center'}
						justifyContent={'center'}
					>
						{resumes.map((resume, i) => {
							return (
								<Chip
									key={i}
									variant="outlined"
									label={
										resume.name.length > 20
											? resume.name.substring(0, 20) + '...'
											: resume.name
									}
									onDelete={handleResumeDelete(i)}
								/>
							);
						})}
					</Stack>
				)}
				{resumes.length > 0 && (
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={2}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Button
							variant="outlined"
							style={{
								textTransform: 'none',
							}}
							sx={{
								textTransform: 'none',
								color: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
									color: 'white',
									border: '1px solid #224C84',
								},
								border: '1px solid #224C84',
							}}
							startIcon={<UploadIcon />}
							disabled={uploadResumeLoading}
							onClick={uploadResumes}
						>
							Upload Resumes
						</Button>
						<Button
							variant="outlined"
							style={{
								textTransform: 'none',
							}}
							sx={{
								textTransform: 'none',
								color: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
									color: 'white',
									border: '1px solid #224C84',
								},
								border: '1px solid #224C84',
							}}
							startIcon={<ClearIcon />}
							disabled={uploadResumeLoading}
							onClick={() => setResumes([])}
						>
							Clear All
						</Button>
					</Stack>
				)}
			</>
		</Stack>
	);
};

export default ResumeEvaluation;
